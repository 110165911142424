// Fonts
/*
@import url('https://fonts.googleapis.com/css?family=Nunito');
*/

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// FA
@import "~font-awesome/scss/font-awesome";



$btn-padding-y-xs:      .1rem;
$btn-padding-x-xs:      .3rem;
$btn-font-size-xs:      $font-size-sm;
$btn-line-height-xs:    $line-height-sm;
$btn-border-radius-xs:  0;

.btn-xs {
  @include button-size($btn-padding-y-xs, $btn-padding-x-xs, $btn-font-size-xs, $btn-line-height-xs, $btn-border-radius-xs);
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .border#{$infix}  { border: $border-width solid $border-color !important; }
    .border#{$infix}-left  { border-left: $border-width solid $border-color !important; }
    .border#{$infix}-right { border-left: $border-width solid $border-color !important; }
    .border#{$infix}-top  { border-top: $border-width solid $border-color !important; }
    .border#{$infix}-bottom { border-bottom: $border-width solid $border-color !important; }
    .border#{$infix}-none  { border: 0 !important; }
  }
}

img {max-width: 100%;}

.text-vertical > * {
    writing-mode: vertical-rl;
}
.batch-select {
    cursor: pointer;
}

.scroll-x {
    overflow-x: scroll;
}

/* tables */

.card-table {
    margin-bottom: 0;
}
.card-table tr:first-child td, .card-table tr:first-child th {
    border-top: 0;
}
.card-header .pagination,
.card-footer .pagination,
.card-header .form-group,
.card-header .card-title,
.card-header label
{
    margin-bottom: 0;
}


/* forms */

.form-group.required > label::after
 {content: "*";}

